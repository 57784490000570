<template>
  <div :class="`pseudo-table--cell pseudo-table--cell-size--${size}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: "TableCell",
  props: {
    size: {
      type: String,
      default: "xs"
    }
  }
};
</script>

<style lang="scss">
.pseudo-table--cell {
  font-size: 13px;
  strong {
    font-size: inherit;
  }
  .user-info {
    width: 100%;
    max-width: 100%;
  }
}
</style>
