<template>
  <Btn type="default" :active="isActive">
    <div class="d-flex">
      <BtnContent :icon="isActive ? 'filter-fill' : 'filter'" label="Filter" />
      <span v-if="isActive" class="filter-count">+{{ count }}</span>
    </div>
  </Btn>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import BtnContent from "@/components/ui/Buttons/BtnContent";
export default {
  name: "FilterBtn",
  components: { BtnContent, Btn },
  props: { count: { type: Number, default: 0 } },
  computed: {
    isActive() {
      return this.count > 0;
    }
  }
};
</script>

<style scoped>
.filter-count {
  margin-left: 0.5em;
}
</style>
